<script setup>
import { computed, useAttrs, ref } from 'vue'
import KIcon from '@/components/kipu-ui/icons/KIcon'

/*
  Required to properly fall through attributes
  when a parent component is using inheritAttrs=false
*/
const attrs = useAttrs()

const basicFocusClasses = `
  hover:tw-text-white
  hover:tw-outline-none
  hover:tw-bg-k-true-blue
  hover:tw-border-k-true-blue

  focus:tw-text-white
  focus:tw-outline-none
  focus:tw-bg-k-true-blue
  focus:tw-border-k-true-blue

  focus:tw-ring-2
  focus:tw-ring-offset-2
  focus:tw-ring-k-true-blue
`.split(/\s+/).join(' ')

const specialFocusClasses = `
  hover:tw-text-white
  hover:tw-outline-none
  hover:tw-bg-k-purple
  hover:tw-border-k-purple

  focus:tw-text-white
  focus:tw-outline-none
  focus:tw-bg-k-purple
  focus:tw-border-k-purple

  focus:tw-ring-2
  focus:tw-ring-offset-2
  focus:tw-ring-k-purple
`.split(/\s+/).join(' ')

const filledSpecialClasses = `
  hover:tw-text-white
  hover:tw-bg-k-violet-500
  hover:tw-border-k-violet-500

  active:tw-bg-k-purple-700
  active:tw-border-k-purple-700
`.split(/\s+/).join(' ')

const textClasses = `
  hover:tw-text-k-dark-blue-700
  hover:tw-bg-k-info-light

  active:tw-bg-k-info-light
  active:tw-text-k-true-blue

  focus:tw-text-k-true-blue
  focus:tw-outline-none

  focus:tw-ring-2
  focus:tw-ring-offset-2
  focus:tw-ring-k-true-blue
`.split(/\s+/).join(' ')

const dangerClasses = `
  hover:tw-bg-k-red-700
  hover:tw-border-k-red-700

  active:tw-bg-k-error-dark

  focus:tw-border-k-red-800
  focus:tw-outline-none
`.split(/\s+/).join(' ')

const props = defineProps({
  mode: {
    type: String,
    validator: function (value) {
      return ['primary', 'secondary', 'text', 'special', 'filledSpecial', 'additional', 'icon'].indexOf(value) !== -1
    },
    default: 'primary',
  },
  icon: {
    type: String,
    default: '',
  },
  iconPosition: {
    type: [String, null, undefined],
    default: 'before',
  },
  iconSize: {
    type: [Number, String],
    default: 18,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  noShadow: {
    type: Boolean,
    default: false,
  },
  uppercase: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    validator: function (value) {
      return ['small', 'large'].indexOf(value) !== -1
    },
    default: 'small',
  },
  link: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
  },
  class: {
    type: String,
    default: '',
  },
  iconClass: {
    type: String,
    default: '',
  },
})

const el = ref(null)
const basic = computed(() => {
  return ['primary', 'secondary'].indexOf(props.mode) !== -1
})

const additionalish = computed(() => {
  return ['additional', 'icon'].indexOf(props.mode) !== -1
})

const classes = computed(() => ({
  'tw-px-4': !props.noPadding,
  'tw-py-2': !props.noPadding && props.mode !== 'icon',
  'tw-py-3': !props.noPadding && props.mode === 'icon',
  'tw-p-0': props.noPadding,
  'tw-uppercase': props.uppercase,
  'tw-inline-flex tw-items-center tw-justify-center tw-border-2 tw-border-solid tw-font-semibold tw-rounded-full hover:tw-outline-none focus:tw-outline-none': true,
  'tw-text-sm  tw-max-h-10': props.size === 'small', // max-h fixes when wrapped in nested flex seems to get larger
  'tw-text-base': props.size === 'large',
  [basicFocusClasses]: basic.value,
  [specialFocusClasses]: props.mode === 'special',
  [filledSpecialClasses]: props.mode === 'filledSpecial',
  [textClasses]: props.mode === 'text',
  [dangerClasses]: props.mode === 'danger',
  'tw-text-white tw-outline-none tw-bg-k-purple tw-border-k-purple': props.mode === 'filledSpecial',
  'tw-text-white tw-bg-k-dark-blue tw-border-transparent': props.mode === 'primary',
  'tw-border-k-dark-blue disabled:tw-border-k-dark-blue-300': props.mode === 'secondary',
  'tw-bg-transparent tw-border-transparent tw-shadow-none tw-text-k-true-blue': props.mode === 'text',
  'tw-bg-k-error-dark tw-border-k-error-dark tw-shadow-none tw-text-white': props.mode === 'danger',
  'tw-border-k-purple tw-text-k-purple disabled:tw-border-k-purple-300': props.mode === 'special',
  'tw-bg-white': props.mode === 'secondary' || props.mode === 'special',
  'tw-text-k-dark-blue': props.mode !== 'primary' && props.mode !== 'special' && props.mode !== 'filledSpecial',
  'disabled:tw-bg-k-gray-300 disabled:tw-text-k-gray-700': !additionalish.value,
  'disabled:tw-border-k-gray-300': props.mode === 'filledSpecial',
  'tw-border-transparent focus:tw-border-k-true-blue focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-k-true-blue disabled:tw-bg-transparent disabled:tw-text-k-gray-300': additionalish.value,
  'hover:tw-bg-k-gray-300': props.mode === 'additional',
  'tw-bg-transparent': props.mode === 'icon',
  'hover:tw-bg-k-sky-blue': props.mode === 'icon' && !/hover:tw-bg-/.test(String(props.class)),
  'tw-shadow-md': !props.disabled && !additionalish.value && !props.noShadow,
  'tw-shadow-none': props.disabled || additionalish.value || props.noShadow,
  'tw-cursor-pointer hover:tw-cursor-pointer': !props.disabled,
  'tw-cursor-not-allowed': props.disabled,
  [props.class]: !!props.class,
}))

const iconClasses = computed(() => ({
  'tw-mr-2': props.mode !== 'icon',
  'tw-leading-none': true,
  [props.iconClass]: !!props.iconClass,
}))

const caption = computed(() => (
  (props.mode !== 'icon') ? props.text : ''
))
defineExpose({ el })
</script>

<template>
  <component
    :is="props.link ? 'a' : 'button'"
    ref="el"
    :type="!props.link && (props.type || 'button')"
    :disabled="disabled"
    :class="classes"
    data-skip-legacy="true"
    v-bind="attrs"
  >
    <KIcon
      v-if="icon && iconPosition !== 'after'"
      :class="iconClasses"
      :icon="icon"
      :size="iconSize"
      theme="none"
    />
    <slot>{{ caption }}</slot>
    <KIcon
      v-if="icon && iconPosition === 'after'"
      :class="iconClasses"
      :icon="icon"
      :size="iconSize"
      theme="none"
    />
  </component>
</template>
