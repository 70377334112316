<script setup>
import { ref, watch } from 'vue'
import { useAppSlideoutStore, locationTypes } from '@emr/stores/AppSlideoutStore'
import { useElementSize } from '@vueuse/core'

defineOptions({ inheritAttrs: false })

const emit = defineEmits(['open', 'close'])
const store = useAppSlideoutStore()
const { isDrawerOpen, registerAvailableDrawer } = store
const parent = ref()
const isOpen = isDrawerOpen(locationTypes.content)
const mainDom = ref(document.getElementById('main-area'))
const drawer = ref()
const drawerId = 'vue-3-content-drawer'
const { height: headerHeight } = useElementSize(document.getElementById('header'))
const root = document.documentElement
registerAvailableDrawer(locationTypes.content, parent)

const setElements = () => {
  // create mount point for drawer dynamically based on main content element
  // helps ensure that the drawer renders correctly for legacy views
  mainDom.value = document.getElementById('main-area')
  drawer.value = document.getElementById(drawerId) || (() => {
    const div = document.createElement('div')
    div.setAttribute('id', drawerId)
    return div
  })()

  mainDom.value?.after?.(drawer.value)

  // On page unload, clean up elements
  return () => {
    drawer.value?.remove?.()
    drawer.value = null
    mainDom.value = null
  }
}

setElements()

const drawerSize = useElementSize(drawer, true)

const onOpenChange = (value) => {
  if (!mainDom.value && !drawer.value) {
    return
  }

  if (value) {
    drawer.value?.classList?.add?.('open')
    mainDom.value?.classList?.add?.('kipu-vue-drawer-content-wrapper', 'kipu-vue-drawer-content-open')
    emit('open')
  } else {
    drawer.value?.classList?.remove?.('open')
    mainDom.value?.classList?.remove?.('kipu-vue-drawer-content-open')
    emit('close')
  }
}

packLoader(setElements)

watch(isOpen, onOpenChange)

watch(headerHeight, (height) => {
  drawer.value.style.setProperty('top', `${height}px`)
  drawer.value.style.setProperty('height', `calc(100% - ${height}px)`)
})

watch(drawerSize.width, (width) => {
  root.style.setProperty('--content-drawer-width', `${width || 0}px`)
})
</script>

<template>
  <Teleport
    to="#vue-3-content-drawer"
  >
    <div
      ref="parent"
      :class="
        isOpen
          ? 'tw-relative tw-h-full tw-w-full tw-overflow-hidden tw-p-5'
          : 'tw-hidden'
      "
    />
  </Teleport>
</template>

<style>
@media screen {
  #vue-3-content-drawer {
    @apply tw-w-[50vw] tw-h-0 tw-max-w-0 tw-overflow-hidden
      tw-block tw-bg-transparent
      tw-transition-[max-width] tw-duration-300 tw-ease-in-out;
    &.open {
      @apply tw-fixed tw-right-0 tw-z-10 tw-max-w-3xl #{!important};
      scrollbar-gutter: stable;
    }
  }
  .kipu-vue-drawer-content-wrapper {
    @apply
      tw-transition-[width] tw-duration-150 tw-ease-out;
    &.kipu-vue-drawer-content-open {
      width: calc(100% - var(--content-drawer-width)) !important;
    }

  }
}
@media print {
  #vue-3-content-drawer {
    @apply tw-hidden;
  }

  .kipu-vue-drawer-content-wrapper {
    &.kipu-vue-drawer-content-open {
      @apply tw-w-screen #{!important};
    }
  }
}
</style>
