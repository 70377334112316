<script setup>
import { ref } from 'vue'
import { customFetch } from '@/lib/utils/Fetch/Base'
import { useLastActivityStore } from '@/apps/emr/stores/LastActivityStore'
import { Kipu } from '@/lib/constants/Kipu'
import KButton from '@/components/kipu-ui/buttons/KButton'

const federationEnabled = () => !!Kipu.config?.auth?.federation?.enabled

const { keepWorking } = useLastActivityStore()
const isSubmitting = ref(false)
const error = ref(null)
const federatedAuth = ref(federationEnabled())

const runSignIn = async (event) => {
  event.preventDefault()
  isSubmitting.value = true

  try {
    const formData = new FormData(event.target)

    if (federatedAuth.value) {
      error.value = null
      const domains = Kipu.config.auth.federation.domains || []
      const email = formData.get('user[login]') || ''

      if (!email.includes('@')) {
        error.value = 'Please enter a valid email address.'
        return
      }

      const domain = email.split('@')[1].trim()

      if (domains.includes(domain) && Kipu.config.auth.federation.url) {
        window.location.href = Kipu.config.auth.federation.url.replace('{email}', email)
      } else {
        federatedAuth.value = false
      }

      return
    }

    const response = await customFetch(event.target.action, {
      method: 'POST',
      body: formData,
      json: true,
      multipart: true,
    })

    const { success } = await response.json()
    if (success) {
      keepWorking()
      error.value = null
      federatedAuth.value = federationEnabled()
    } else {
      throw new Error('Could not authenticate user. If you continue to have trouble, please contact the designated Super Administrator for your facility.')
    }
  } catch (err) {
    logger.error(err)
    error.value = 'Could not authenticate user. If you continue to have trouble, please contact the designated Super Administrator for your facility.'
  } finally {
    isSubmitting.value = false
  }
}
</script>

<template>
  <form
    autocomplete="login"
    class="tw-w-full"
    action="/users/sign_in.json"
    accept-charset="UTF-8"
    method="post"
    @submit="runSignIn($event)"
  >
    <div
      v-if="error"
      class="tw-bg-red-100 tw-rounded tw-p-4 tw-mb-2"
    >
      <p class="tw-text-red-700 tw-text-sm tw-mb-1">
        {{ error }}
      </p>
    </div>
    <input
      name="utf8"
      type="hidden"
      value="✓"
    >
    <input
      autofocus="autofocus"
      autocomplete="username"
      placeholder="Email or Username"
      class="tw-text-center tw-w-full tw-border tw-rounded tw-mb-4 tw-min-h-[50px]"
      type="text"
      name="user[login]"
      @change="federatedAuth = federationEnabled()"
    >
    <input
      autocomplete="current-password"
      placeholder="Password"
      class="tw-text-center tw-w-full tw-border tw-rounded tw-my-4 tw-min-h-[50px]"
      :class="{ 'tw-hidden': federatedAuth }"
      type="password"
      name="user[password]"
    >
    <KButton
      type="submit"
      :disabled="isSubmitting"
    >
      {{ federatedAuth ? 'Continue' : 'Sign In' }}
    </KButton>
  </form>
</template>
